/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 *
 * Google CDN, Latest jQuery
 * To use the default WordPress version of jQuery, go to lib/config.php and
 * remove or comment out: add_theme_support('jquery-cdn');
 * ======================================================================== */

// TODO Margin space under articles 
// TODO Voir les bords des autres articles pour toute résolution < 768
toastr.options = {
  "closeButton": false,
  "debug": false,
  "newestOnTop": false,
  "progressBar": true,
  "positionClass": "toast-bottom-center",
  "preventDuplicates": false,
  "onclick": null,
  "showDuration": "300",
  "hideDuration": "1000",
  "timeOut": "5000",
  "extendedTimeOut": "1000",
  "showEasing": "swing",
  "hideEasing": "linear",
  "showMethod": "fadeIn",
  "hideMethod": "fadeOut"
};


var MobileArticlesList = function() {
    var $ = jQuery;
    
    this.$container = $(".slidedeck-content");
    this.$movable = $(".slidedeck-container");
    this.$articles = this.$container.children("article");
    this.$first = this.$articles.first();
    this.currentArticle = 1;
    this._hammer = null;
    this.bound = false;
    
    this.refreshStyles = function() {
        this.$articles.each(function(idx, el) {
            if (idx + 1 !== this.currentArticle) {
                $(el).addClass("slidedeck-inactive");
            } else {
                $(el).removeClass("slidedeck-inactive");
            }
        }.bind(this));
    }.bind(this);
    
    this.nextArticle = function() {
        if (this.currentArticle === this.$articles.length) {
            return;
        } 
        
        this.currentArticle ++;
        this.centerArticles();
        this.refreshStyles();
    }.bind(this);
    
    var currentMarginLeft = 0;
    
    this.previousArticle = function() {
        if (this.currentArticle === 1) {
            return;
        }
        
        this.currentArticle --;
        this.centerArticles();
        this.refreshStyles();
    }.bind(this);
    
    this.centerArticles = function() {
        if (!this.bound) {
            return; // A resize event might still be in the event loop so we check
        }
        var articleFullWidth = $(this.$articles.get(this.currentArticle - 1)).outerWidth(true);
        var marg = this.$movable.width() / 2 - articleFullWidth / 2;
        this.$container.css("margin-left", marg - articleFullWidth * (this.currentArticle - 1));
    }.bind(this);
    
    this.onKeydown = function(ev) {
        if (ev.keyCode === 37) {
            this.previousArticle();
        } else if (ev.keyCode === 39) {
            this.nextArticle();
        }
    }.bind(this);
    
    this.bindEvents = function() {
        this._hammer.on('swiperight', this.previousArticle);
        this._hammer.on('swipeleft', this.nextArticle);
        
        $(document).on("keydown", this.onKeydown);
        $(window).on("resize", this.centerArticles);
        this.bound = true;
        
        setTimeout(function(){
            // clean masonry stuffs after its last resize evt
            this.$articles
                .css("left", "")
                .css("top", "");
            
            this.$container
                .css("width", "")
                .css("height", "");
            
        }.bind(this), 30);
    };
    
    this.unbindEvents = function() {
        this._hammer.off('swiperight', this.previousArticle);
        this._hammer.off('swipeleft', this.nextArticle);
        
        $(document).off("keydown", this.onKeydown);
        $(window).off("resize", function(e) {
            e.preventDefault();
            this.centerArticles();
        }.bind(this));
        
        this.$container.css("margin-left", 0);
        this.$movable.scrollLeft(0);
        this.$articles.css("opacity", 1);
        this.bound = false;
    };
    
    this._hammer = new Hammer.Manager(this.$container.get(0), {
        domEvents: true
    });
    
    this._hammer.add([new Hammer.Swipe({
        velocity: 0.30
    })]);
    
    this.bindEvents();
    this.centerArticles();
    this.refreshStyles();
};
 
var DynamicArticles = function() {
    var $ = jQuery;
    var $container = $('.slidedeck-content');
    var msnry = null;
    var mobileList = null;
    
    var onResize = function() {
        var isMdOrLarger = window.matchMedia("(min-width: 768px)").matches;
        if (isMdOrLarger && !msnry) {
            if (mobileList !== null) { 
                mobileList.unbindEvents();
                mobileList = null;
            }
            // Enabling masonry
            $container.imagesLoaded(function () {
                msnry = new Masonry($container[0], {
                    itemSelector: '.js-movable',
                    isFitWidth: true,
                    transitionDuration: 0
                });
            });
            msnry = true; // Forcing desktop rendering
        } else if (!isMdOrLarger && !mobileList) {
            if (msnry !== null) {
                msnry.destroy();
                msnry = null;
            }
            mobileList = new MobileArticlesList();
        }
    };
    
    $(window).on('resize', onResize);
    onResize();
};

(function ($) {

    // Use this variable to set up the common and page specific functions. If you
    // rename this variable, you will also need to rename the namespace below.
    var Sage = {
        // All pages
        'common': {
            init: function () {
                // JavaScript to be fired on all pages
                $(function () {
                    $('#dropdown-menu-search input, #dropdown-menu-user input, #dropdown-menu-user a').click(function (e) {
                        e.stopPropagation();
                    });
                });

                $('.lwa-links-register').click(function (e) {
                    e.preventDefault();
                    $('.register-form').reveal({
                        modalbgclass: 'lwa-modal-bg',
                        dismissmodalclass: 'lwa-modal-close'    //the class of a button or element that will close an open modal
                    });
                });

                if (window.Typekit && window.Typekit.load) {
                    window.Typekit.load();
                }
                
                window.createMobileExpandMenu = function($el) {
                    $el = $el || $(this);
                    var $btn = $("<div class='mobile-expand-toggle-btn'>");
                    
                    if (!$el.hasClass("js-mobile-expand-no-reorder") && window.matchMedia("(max-width: 768px)").matches) {
                        $el.find("a.active").parent().prependTo($el);
                    }
                    $btn.prependTo($el);
                    
                    $btn.click(function() {
                        $el.toggleClass("mobile-expand-on");
                    });
                    
                    
                    // For ajax pages
                    $el.find("a").click(function() {
                        var $a = $(this);
                        
                        $el.find("a").removeClass("active");
                        $a.addClass("active");
                        
                        if (!$el.hasClass("js-mobile-expand-no-reorder") && window.matchMedia("(max-width: 768px)").matches) {
                            $el.find("a.active").parent().prependTo($el);
                            $btn.prependTo($el);
                            $el.removeClass("mobile-expand-on");
                        }
                        
                    });
                    
                    $el.find("a.js-prevent-default").click(function(e) {
                        e.preventDefault();
                    });
                };
                
                $(".mobile-expand").each(window.createMobileExpandMenu);
            },
            finalize: function () {
                // JavaScript to be fired on all pages, after page specific JS is fired
                if (!localStorage.getItem("agreedCookieTerms")) {
                    toastr.info("Nous utilisons des cookies pour vous garantir la meilleure expérience " + 
                        "sur notre site. Si vous continuez à utiliser ce dernier, nous considérerons " + 
                        "que vous acceptez l'utilisation des cookies.<br/><br/><i>Cliquez ici pour continuer</i>", "Utilisation de cookies", {
                            "tapToDismiss": true,
                            "progressBar": false,
                            "timeOut": 0,
                            "onHidden": function() {
                                localStorage.setItem("agreedCookieTerms", true);
                            }
                        });
                    
                }
            }
        },
        // Home page
        'home': {
            init: function () {
                var $container = $('.articles-container');
                var isMdOrLarger = false;
                var isXs = false;
                var msnry = null;

                
                $('.more').click(function (e) {
                    e.preventDefault();
                    $(window).scrollTo('.articles', 500);
                    return false;
                });
                
                var goButton = $('.breacrumb-action-go');
    
                $("#breadcrumb-from-input, #breadcrum-to-input").on("input", function() {
                    var fromValue = $(this).val();
                    if (fromValue) {goButton.removeClass('disabled');}
                    else {goButton.addClass('disabled');}
                });
                
                DynamicArticles();
                
            },
            finalize: function () {
                // JavaScript to be fired on the home page, after the init JS
            }
        }
    };

    // The routing fires all common scripts, followed by the page specific scripts.
    // Add additional events for more control over timing e.g. a finalize event
    var UTIL = {
        fire: function (func, funcname, args) {
            var fire;
            var namespace = Sage;
            funcname = (funcname === undefined) ? 'init' : funcname;
            fire = func !== '';
            fire = fire && namespace[func];
            fire = fire && typeof namespace[func][funcname] === 'function';

            if (fire) {
                namespace[func][funcname](args);
            }
        },
        loadEvents: function () {
            // Fire common init JS
            UTIL.fire('common');

            // Fire page-specific init JS, and then finalize JS
            $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function (i, classnm) {
                UTIL.fire(classnm);
                UTIL.fire(classnm, 'finalize');
            });

            // Fire common finalize JS
            UTIL.fire('common', 'finalize');
        }
    };

    // Load Events
    $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.

jQuery(document).ready(function( $ ) {
    $('.masonry').masonry({
        itemSelector: '.masonry-item',
        columnWidth: 270,
        gutter: 15
    });
});

jQuery(document).ready(function( $ ) {
    let divStateMapContent = $('#state-map-content')
    let regex = /\/etat\/[a-zA-Z]+/g
    if (window.location.href.search(regex) > -1 && divStateMapContent) {
        $([document.documentElement, document.body]).animate({
            scrollTop: divStateMapContent.offset().top
        }, 1000);
    }
});